import { ActionReducerMapBuilder, AsyncThunk, Draft, EntityAdapter, EntityId, EntityState } from '@reduxjs/toolkit';
import { RequestState, WithRequestState, WithRequestStateGetById as WithRequestStateById } from './requestState';
import { ThunkApi } from '..';

type DefaultActionBuilder<T> = ActionReducerMapBuilder<WithRequestState<T>>;

type ActionWithStatus<T = unknown> = { meta: { requestStatus: RequestState, arg: T } }

export const handleGetEntitiesThunk = <T, Id extends EntityId>(
  builder: DefaultActionBuilder<EntityState<T, Id>>,
  entityAdapter: EntityAdapter<T, Id>,
  getAll: AsyncThunk<T[], void, ThunkApi>,
) => {
  return handleThunk<EntityState<T, Id> & WithRequestState, T[]>(builder, getAll, (state, action) => {
    entityAdapter.setAll(state as EntityState<T, Id>, action.payload);
  });
};

export const handleThunk = <TState, T = unknown,
  TThunkArg = void>(
    builder: ActionReducerMapBuilder<WithRequestState<TState>>,
    get: AsyncThunk<T, TThunkArg, ThunkApi>,
    onFulfilled?: (state: Draft<TState>, action: { payload: T, meta: { arg: TThunkArg, requestId: string } }) => void,
) => {
  const handleStatus = (state: Draft<WithRequestState<TState>>, { meta: { requestStatus } }: ActionWithStatus) => {
    state.requestState = requestStatus;
  };
  return builder
    .addCase(get.pending, handleStatus)
    .addCase(get.fulfilled, (state, action) => {
      handleStatus(state, action);
      onFulfilled?.(state, action);
    })
    .addCase(get.rejected, (state, action) => {
      handleStatus(state, action);
      state.error = action.error;
    });
};

export const handleGetEntityByIdThunk = <T, TBuilder extends ActionReducerMapBuilder<WithRequestStateById<EntityState<T, TEntityId>>>, TEntityId extends EntityId = EntityId>(
  builder: TBuilder,
  entityAdapter: EntityAdapter<T, TEntityId>,
  getById: AsyncThunk<T, TEntityId, ThunkApi>,
) => {
  const handleStatus = (state: Draft<WithRequestStateById>, { meta: { requestStatus, arg } }: ActionWithStatus<TEntityId>) => {
    state.requestStateById[arg] = requestStatus;
  };

  return builder
    .addCase(getById.pending, handleStatus)
    .addCase(getById.fulfilled, (state, action) => {
      handleStatus(state, action);
      entityAdapter.upsertOne(state as EntityState<T, TEntityId>, action.payload as T);
    })
    .addCase(getById.rejected, (state, action) => {
      handleStatus(state, action);
      state.error = action.error;
    });
};

export const invalidateGetAll = (state: Draft<WithRequestState>) => {
  state.requestState = 'didInvalidate';
};
