import {
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import { PublicationSummary } from 'services/ApiService';
import { createInsightPublication, createNewsPublication, createVideoPublication, createAlternativeViewPublication, createPodcastPublication, createQuantMotionPicturePublication } from 'store/createPublication';
import { getInsightPublication, getNewsPublication, getVideoPublication, getAlternativeViewPublication, getPodcastPublication, getQuantMotionPicturePublication } from 'store/editPublication/thunks/getPublication';
import { RequestState } from 'store/helpers';
import { handleGetEntitiesThunk, handleThunk, invalidateGetAll } from 'store/helpers/reducerHandlers';
import { getAllPublications } from 'store/publications/thunks/getAllPublications';
import { searchSlice } from 'store/search';

export const publicationAdapter = createEntityAdapter<PublicationSummary>({
  sortComparer: (a, b) => b.lastModificationDateUtc?.toISOString().localeCompare(a.lastModificationDateUtc?.toISOString()),
});

const initialState = publicationAdapter.getInitialState({
  requestState: 'idle' as RequestState,
  error: undefined,
});

export const publicationSlice = createSlice({
  name: 'publications',
  initialState,
  reducers: {},
  extraReducers: builder => {
    [
      getInsightPublication,
      getVideoPublication,
      getNewsPublication,
      getAlternativeViewPublication,
      getPodcastPublication,
      getQuantMotionPicturePublication,
    ].forEach((thunk) => {
      handleThunk(builder, thunk, (state, { payload }) => {
        const publicationUpdated = state.entities[payload.id];
        if (!publicationUpdated) {
          state.requestState = 'didInvalidate';
          return;
        }
        publicationAdapter.upsertOne(state, { ...publicationUpdated, ...payload });
      });
    });
    handleGetEntitiesThunk(builder, publicationAdapter, getAllPublications)
      .addMatcher((action) =>
        [
          createNewsPublication.fulfilled.type,
          createVideoPublication.fulfilled.type,
          createInsightPublication.fulfilled.type,
          createAlternativeViewPublication.fulfilled.type,
          createPodcastPublication.fulfilled.type,
          createQuantMotionPicturePublication.fulfilled.type,
          searchSlice.actions.setQuickSearch.type,
          searchSlice.actions.setPublicationType.type,
          searchSlice.actions.setPage.type,
        ].includes(action.type), invalidateGetAll);
  },
});