import React from 'react';
import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';
import { configuration } from 'config/constants';

declare global {
  namespace React {
    namespace JSX {
      interface IntrinsicElements {
        'sgwt-mini-footer': {
          'id': string;
          'no-border': boolean;
          'mode': string;
          'contact-us': string;
        };
      }
    }
  }
}

export const Footer: React.FC = () => {
  return <div className="footer w-100 d-flex border-top bg-lvl1">
    <div className="flex-grow-1">
      <WidgetLazyLoader script={`${configuration.widgetCdnBaseUrl}/widgets/sgwt-mini-footer/v4/sgwt-mini-footer.js`}>
        <sgwt-mini-footer
          id="sgm-mini-footer"
          no-border
          mode="sg-markets"
          contact-us="{'mail':'sg-markets@sgcib.com'}"
        />
      </WidgetLazyLoader>
    </div>
  </div>;
};
