import classNames from 'classnames';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { RoutePaths } from 'routes/RoutePaths';
import { SgwtAccountCenter } from './SgwtAccountCenter';

export const Header: React.FC = () => {
  return <header className="header position-relative">
    <nav className="navbar border-bottom">
      <div className="navbar-title">
        <NavLink
          to={RoutePaths.Home.url()}
          className={({ isActive }) => classNames('navbar-title-link', { active: isActive })}
        >
          <div className="navbar-logo">
            <img src="https://shared.sgmarkets.com/assets/images/logo/socgen_logo.svg" alt="SG logo Glyph" />
          </div>

          <div className="navbar-title-divider" />
          <div className="navbar-service-name text-nowrap">
            Insight public admin
          </div>
        </NavLink>

      </div>
      <div className="navbar-content"/>
      <div className="navbar-toolbar">
        <SgwtAccountCenter />
      </div>
    </nav>
  </header>;
};
