import React from 'react';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { Header } from './Header';
import { HelpCenter } from './HelpCenter';
import { Footer } from './Footer';
import { SplashScreen } from './SplashScreen';
import { SgwtConnect } from './SgwtConnect';

interface IProps {
  children: React.ReactNode;
}

export class Layout extends React.PureComponent<IProps> {
  public render() {
    const { children } = this.props;
    return (
      <>
        <Header />
        <main className="bg-lvl2" style={{ minHeight: 'calc(100vh - var(--header-height))' }}>
          <ErrorBoundary>
            {children}
          </ErrorBoundary>
        </main>
        <Footer />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          closeButton={false}
          icon={false}
          newestOnTop
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          stacked
        />
        <HelpCenter />
        <SgwtConnect />
        <SplashScreen />
      </>
    );
  }
}