import {
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import { PublicationCategory } from 'services/ApiService';
import { RequestState } from 'store/helpers';
import { handleGetEntitiesThunk } from 'store/helpers/reducerHandlers';
import { getCategories } from './thunks/getCategories';

export const categoriesAdapter = createEntityAdapter<PublicationCategory>({
  sortComparer: (x, y) => x.name?.localeCompare(y.name ?? '') ?? -1,
});

const initialState = categoriesAdapter.getInitialState({
  requestState: 'idle' as RequestState,
  error: undefined,
});

export const categoriesSlice = createSlice({
  name: '@CATEGORIES',
  initialState,
  reducers: {},
  extraReducers: builder => {
   handleGetEntitiesThunk(builder, categoriesAdapter, getCategories);
  },
});