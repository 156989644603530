import {
  AsyncThunk,
  EntityAdapter,
  EntityId,
  EntityState,
} from '@reduxjs/toolkit';
import { ThunkApi, RootState, useAppDispatch } from '..';
import { WithRequestState, WithRequestStateGetById } from './requestState';
import { RequestState } from './requestState';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

export const useGetById = <T, TId extends string | number>(id: TId, { selector, entityAdapter, getById }:
  {
    selector: (state: RootState) => WithRequestStateGetById<EntityState<T, TId>>;
    entityAdapter: EntityAdapter<T, TId>;
    getById: AsyncThunk<T, TId, ThunkApi>
  }) => {

  const { selectById } = useMemo(() => {
    return entityAdapter.getSelectors(selector);
  }, [entityAdapter, selector]);
  
  return useRequest({
    request: getById(id),
    requestState: x => selector(x).requestStateById[id],
    fromStore: x => selectById(x, id),
  });
};

export const useGetByIdIfDefined = <T, TId extends string | number>(id: TId | undefined, { selector, entityAdapter, getById }:
  {
    selector: (state: RootState) => WithRequestStateGetById<EntityState<T, TId>>;
    entityAdapter: EntityAdapter<T, TId>;
    getById: AsyncThunk<T, TId, ThunkApi>
  }) => {

  const { selectById } = useMemo(() => {
    return entityAdapter.getSelectors(selector);
  }, [entityAdapter, selector]);

  return useRequest({
    request: !id ? undefined : getById(id),
    requestState: x => !id ? 'fulfilled' : selector(x).requestStateById[id],
    fromStore: x => !id ? undefined : selectById(x, id),
  });
};

export const useGetAll = <T, Id extends EntityId>({ selector, entityAdapter, getAll }:
  {
    selector: (state: RootState) => EntityState<T, Id> & WithRequestState;
    entityAdapter: EntityAdapter<T, Id>;
    getAll: AsyncThunk<T[], void, ThunkApi>,
  }) => {
  const { selectAll } = useMemo(() => entityAdapter.getSelectors(selector), [entityAdapter, selector]);
  return useRequest({
    request: getAll(),
    requestState: x => selector(x).requestState,
    fromStore: x => selectAll(x),
  });
};

interface UseRequestProps<T, TArg> {
  requestState: (rootState: RootState) => RequestState;
  request: AsyncThunkAction<unknown, TArg, { state: RootState }> | undefined;
  fromStore: (x: RootState) => T;
}


export const useRequest = <T, TArg>({
  request,
  requestState,
  fromStore,
}: UseRequestProps<T, TArg>): T => {

  const dispatch = useAppDispatch();
  const state = useSelector<RootState, RequestState>(requestState) ?? 'idle';
  const shouldRequest = state === 'idle' || state === 'didInvalidate';
  const storedData = useSelector(fromStore);
  useEffect(() => {
    if (shouldRequest && request) {
      dispatch(request);
    }
  });
  return storedData;
};